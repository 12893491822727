<template>
<div>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Conversiones</h1>
                <p class="desc mt-0 mb-1">Listado de ventas generadas por el tráfico.</p>
            </v-alert>
        </v-col>

        <!-- Search Bar y Filtros -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" @input="fetchConversions" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line hide-details outlined class="mb-2 mx-0 px-0"></v-text-field>
            </v-col>

            <v-col cols="12" md="2" class="px-md-2 px-0">
                <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="startDate" label="Fecha Inicio" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" :max="endDate" :min="minAllowedDate" @input="startDateMenu = false; fetchConversions()"></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2" class="pr-md-2 px-0">
                <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="endDate" label="Fecha Fin" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" :min="startDate" :max="maxAllowedDate" @input="endDateMenu = false; fetchConversions()"></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2" class="pr-md-2 px-0">
                <v-select v-model="sortField" :items="sortFieldOptions" label="Ordenar por" outlined hide-details @change="fetchConversions"></v-select>
            </v-col>

            <v-col cols="12" md="2" class="pr-md-2 px-0">
                <v-select v-model="sortOrder" :items="sortOrderOptions" label="Orden" outlined hide-details @change="fetchConversions"></v-select>
            </v-col>
        </v-row>

        <!-- Tabla de datos -->
        <v-data-table :headers="headers" :items="conversions" :loading="loading" class="elevation-1 mx-3 px-2 py-4" :items-per-page="perPage" :server-items-length="totalConversions" :page.sync="page" :footer-props="{
          itemsPerPageOptions: [50, 100, 200, -1],
          showFirstLastPage: true
        }" @update:page="fetchConversions" @update:items-per-page="changePerPage">
            <!-- Columnas y Templates -->

            <template v-slot:item.logo_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="40" class="clickable-avatar" @click="showLogo(item.logo_path)" v-bind="attrs" v-on="on">
                            <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                            <v-avatar v-else color="black">
                                <span class="white--text">{{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}</span>
                            </v-avatar>
                        </v-avatar>
                    </template>
                    <span>Ver Imagen</span>
                </v-tooltip>
            </template>

            <template v-slot:item.email="{ item }">
                {{ item.email }}
            </template>

            <template v-slot:item.platform="{ item }">
                {{ item.platform }}
            </template>

            <template v-slot:item.sale="{ item }">
                $ {{ item.sale }} USD
            </template>

            <template v-slot:item.platform_percent="{ item }">
                {{ item.platform_percent }}
            </template>

            <template v-slot:item.payment="{ item }">
                $ {{ item.payment }} USD
            </template>

            <template v-slot:item.creator_percent="{ item }">
                {{ item.creator_percent }}
            </template>

            <template v-slot:item.payment_creator="{ item }">
                $ {{ item.payment_creator }} USD
            </template>

            <template v-slot:item.conversions="{ item }">
                {{ item.conversions }}
            </template>

            <template v-slot:item.payment_date="{ item }">
                {{ formatDate(item.payment_date) }}
            </template>

            <!-- Botones de acciones -->
            <template v-slot:item.actions="{ item }">
                <!-- Botón para impersonar usuario -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.user_id)">
                            <v-icon>mdi-account-switch</v-icon>
                        </v-btn>
                    </template>
                    <span>Impersonar Usuario</span>
                </v-tooltip>

                <!-- Nuevo botón para ir al Harmony -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site_slug)">
                            <v-icon>mdi-web</v-icon>
                        </v-btn>
                    </template>
                    <span>Ir al Harmony</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
            <v-img :src="selectedLogo" width="14rem"></v-img>
        </v-dialog>
    </v-col>
</div>
</template>

<script>
import axios from '@/axios.js';
import moment from 'moment';

export default {
    name: 'Conversions',
    data() {
        const today = moment().format('YYYY-MM-DD');
        const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
        const maxAllowedDate = moment().format('YYYY-MM-DD');
        const minAllowedDate = moment('2023-03-26').format('YYYY-MM-DD');

        return {
            conversions: [],
            search: '',
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalConversions: 0,
            sortField: 'payment_date',
            sortOrder: 'desc',
            sortFieldOptions: [{
                    text: 'Fecha de Pago',
                    value: 'payment_date'
                },
                {
                    text: 'Venta',
                    value: 'sale'
                },
                {
                    text: 'Pago Plataforma',
                    value: 'payment'
                },
                {
                    text: 'Pago Creador',
                    value: 'payment_creator'
                },
            ],
            sortOrderOptions: [{
                    text: 'Mayor a menor',
                    value: 'desc'
                },
                {
                    text: 'Menor a mayor',
                    value: 'asc'
                },
            ],
            startDate: threeMonthsAgo,
            endDate: today,
            startDateMenu: false,
            endDateMenu: false,
            maxAllowedDate: maxAllowedDate,
            minAllowedDate: minAllowedDate,
            headers: [{
                    text: 'Avatar',
                    value: 'logo_path',
                    align: 'start',
                },
                {
                    text: 'Creador',
                    value: 'email',
                    align: 'start',
                },
                {
                    text: 'Plataforma',
                    value: 'platform',
                    align: 'start',
                },
                {
                    text: 'Venta',
                    value: 'sale',
                    align: 'start',
                },
                {
                    text: 'Porcentaje Plataforma',
                    value: 'platform_percent',
                    align: 'start',
                },
                {
                    text: 'Pago Plataforma',
                    value: 'payment',
                    align: 'start',
                },
                {
                    text: 'Porcentaje Creador',
                    value: 'creator_percent',
                    align: 'start',
                },
                {
                    text: 'Pago Creador',
                    value: 'payment_creator',
                    align: 'start',
                },
                {
                    text: 'Conversiones',
                    value: 'conversions',
                    align: 'start',
                },
                {
                    text: 'Fecha de Pago',
                    value: 'payment_date',
                    align: 'start',
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    sortable: false,
                },
            ],
        };
    },
    watch: {
        sortField(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchConversions();
            }
        },
        sortOrder(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchConversions();
            }
        },
        startDate(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchConversions();
            }
        },
        endDate(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchConversions();
            }
        },
    },

    created() {
        this.fetchConversions();
    },
    methods: {
        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        async fetchConversions() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/conversions', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        sort_field: this.sortField,
                        sort_order: this.sortOrder,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    },
                });
                this.conversions = response.data.data;
                this.totalConversions = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchConversions();
        },

        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },

        async impersonateUser(user_id) {
            try {
                const response = await axios.post('/admin/impersonate-user', {
                    user_id,
                });
                let admin = localStorage.getItem("user");
                localStorage.setItem("impersonate_admin", JSON.stringify(admin));

                // Guardar el nuevo token y datos de usuario en el local storage
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("token", response.data.token);

                this.$vs.notify({
                    title: 'Impersonación exitosa',
                    text: `Ahora estás logueado como ${response.data.user.first_name}`,
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                // Redirigir al panel correspondiente basado en el rol del usuario
                if (response.data.user.role_id === 1) {
                    this.$router.push('/panel/admin');
                } else {
                    this.$router.push('/panel');
                }
            } catch (error) {
                console.error('Error impersonating user:', error);
            }
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
    },
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
